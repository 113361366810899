import React from "react";
import { useScroll, useActiveSnap } from "@snap-carousel/react";
import { useResponsiveValue } from "@theme-ui/match-media";

import GridRow from "../../commons/GridRow";
import Tag from "../../commons/Tag";
import Button from "../../commons/Button";
import Heading from "../../commons/Heading";
import Box from "../../commons/Box";
import Text from "../../commons/Text";
import Carousel from "../../commons/Carousel";
import RichTextRenderer from "../../commons/RichTextRenderer";

import { linkResolver } from "../../../utils/links";

import { ReactComponent as DownArrow } from "../../../images/icons/down-arrow.svg";
import Image from "../../commons/Image";

export default function CarouselBlock({ title, list, ...otherProps }) {
  const ref = React.useRef();

  const goToSnapItem = useScroll({ ref });
  const index = useActiveSnap({ ref });

  const handlePreviousClick = React.useCallback(() => {
    goToSnapItem(index - 1);
  }, [goToSnapItem, index]);

  const handleNextClick = React.useCallback(() => {
    goToSnapItem(index + 1);
  }, [goToSnapItem, index]);

  const offset = useResponsiveValue([0, 0, 0]);

  const pageIndex = useResponsiveValue([
    index,
    index,
    Math.max(0, index - offset),
  ]);

  const pages = list.slice(offset * 2, list.length);

  return (
    <GridRow
      as="section"
      sx={{
        py: [5, "gridGap"],
        backgroundColor: "background",
      }}
    >
      <GridRow.Col
        gridColumn={["start / end", "start / span 5"]}
        sx={{ textAlign: ["center", "start"] }}
      >
        <Heading
          variant="h2"
          sx={{
            px: [2, 0],
            mt: [4, 0],
          }}
        >
          {title}
        </Heading>
      </GridRow.Col>
      <GridRow.Col
        gridColumn="1 / -1"
        sx={{
          display: "flex",
          py: ["gridGap", 0],
          maxWidth: "100%",
        }}
      >
        <Carousel ref={ref}>
          {list.map((item) => {
            const buttonProps = item.button[0] && linkResolver(item.button[0]);

            return (
              <Carousel.Slide
                key={item._key}
                columnSpan={[1, 1, 2]}
                sx={{ maxWidth: 700 }}
              >
                <Box
                  className="card"
                  sx={{
                    display: "flex",
                    flexDirection: ["column", "row"],
                    backgroundColor: "white",
                    borderRadius: "card",
                    boxShadow: "card",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      m: [3, 4],
                      pr: [2, 3],
                      mr: [0, 0],
                      borderRight: "1px solid",
                      borderColor: "gray.1",
                      alignItems: "center",
                    }}
                  >
                    {item.image && (
                      <Box
                        sx={{
                          bg: "black",
                          p: 3,
                          borderRadius: "circle",
                          boxShadow: "card",
                          width: 90,
                        }}
                      >
                        <Image
                          alt={item.image.alt}
                          fluid={item.image.asset.fluid}
                        />
                      </Box>
                    )}
                    <Tag sx={{ mt: 3, fontSize: 0 }}>{item.label}</Tag>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      m: [3, 4],
                      ml: [2, 3],
                    }}
                  >
                    <Heading variant="h5">{item.title}</Heading>
                    <Text variant="caps" sx={{ color: "primary" }}>
                      {item.subtitle}
                    </Text>
                    <Box sx={{ mt: 4, fontSize: 3 }}>
                      <RichTextRenderer blocks={item._rawText} />
                    </Box>
                    {buttonProps && (
                      <Button
                        key={buttonProps.to || buttonProps.href}
                        {...buttonProps}
                        variant="gradient"
                        sx={{ mt: 3, alignSelf: "flex-start" }}
                      />
                    )}
                  </Box>
                </Box>
              </Carousel.Slide>
            );
          })}
        </Carousel>
      </GridRow.Col>
      <GridRow.Col sx={{ display: ["none", "block"], justifySelf: "flex-end" }}>
        <Button
          onClick={handlePreviousClick}
          aria-label="Next"
          sx={{
            width: 63,
            height: 63,
            p: [0, 0, 0],
            color: "primary",
            transform: "rotate(0.25turn)",
          }}
          disabled={pageIndex === 0}
        >
          <DownArrow width="13" height="13" aria-hidden />
        </Button>
        <Button
          onClick={handleNextClick}
          aria-label="Next"
          sx={{
            width: 63,
            height: 63,
            p: [0, 0, 0],
            color: "primary",
            transform: "rotate(-0.25turn)",
            ml: 3,
          }}
          disabled={pageIndex === pages.length - 1}
        >
          <DownArrow width="13" height="13" aria-hidden />
        </Button>
      </GridRow.Col>
    </GridRow>
  );
}
