import React from "react";

import Text from "../Text";

export default function Tag({ sx, ...otherProps }) {
  return (
    <Text
      variant="caps"
      sx={{
        color: "primary",
        border: "1px solid",
        borderColor: "primary",
        borderRadius: "default",
        py: 1,
        px: "8px",
        lineHeight: 1,
        ...sx,
      }}
      {...otherProps}
    />
  );
}
