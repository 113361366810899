import React from "react";
import { useDragToScroll } from "@snap-carousel/react";

import Box from "../Box";
import { carouselStyles } from "../../../utils/styles";

const columnsMap = (theme) => ({
  1: `calc(96% - (${theme.space.gridGap} - ${theme.space[2]}))`,
  2: `calc(50% - (${theme.space.gridGap} - ${theme.space[3]}))`,
  3: `calc(33.33% - ((${theme.space.gridGap} - ${theme.space[3]}) * 2) / 3)`,
});

const Carousel = React.forwardRef(({ sx, children, ...otherProps }, ref) => {
  useDragToScroll({ ref });

  React.useEffect(() => {
    ref.current.scrollLeft = -9999;
  }, [ref]);

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        alignContent: "center",
        overflowY: "hidden",
        overflowX: "auto",
        flexDirection: "row",
        maxWidth: "100%",
        flex: 1,
        padding: "3em 0",
        margin: "-3em 0",
        "&:before, &:after": {
          content: "''",
          flex: "0 0 1px",
        },
        ...carouselStyles,
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
});

export default Carousel;

function Slide({ sx, children, columnSpan = [1, 2, 3], ...otherProps }) {
  return (
    <Box
      sx={{
        width: (theme) => columnSpan.map((col) => columnsMap(theme)[col]),
        flexShrink: 0,
        scrollSnapAlign: "center",
        px: [2, 3],
        "&:first-of-type": {
          ml: (theme) => [
            `calc(${theme.space.gridGap} - ${theme.space[2]})`,
            `calc(${theme.space.gridGap} - ${theme.space[3]})`,
          ],
        },
        "&:last-child": {
          mr: (theme) => [
            `calc(${theme.space.gridGap} - ${theme.space[2]})`,
            `calc(${theme.space.gridGap} - ${theme.space[3]})`,
          ],
        },
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Box>
  );
}

Carousel.Slide = Slide;
